import classNames from "classnames"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useId } from "react"
import { useFormContext } from "react-hook-form"

import CurrencyDisplay from "../../../../../../components/currencies/CurrencyDisplay"
import CheckBlock from "../../../../../../components/Forms/CheckBlock/CheckBlock"
import FoldableSection from "../../../../../../components/layout/FoldableSection/FoldableSection"
import useUrbanLanguage from "../../../../../../hooks/urbanLanguage/useUrbanLanguage"
import useMediaPath from "../../../../../../hooks/useMediaPath"
import { getResourceDisplay } from "../../../services/function/functions"
import { formArgs } from "../../services/function"

export const BookableItem = ({ bookable, onSlotSelected, types }) => {
    const { watch } = useFormContext()
    const mediaPath = useMediaPath()
    const { tu } = useUrbanLanguage()
    const id = useId()

    const [ discountFilter ] = watch([
        formArgs.DISCOUNT_FILTER,
    ])

    const manyBookablesToDisplay = (bookable, key) => {
        const display = bookable.bookables?.map(
            (bookable, index) => displayForBookable(bookable, false, true, index),
        ) ?? []
        if (bookable.bookables?.findIndex(item => item.count === 1) !== -1) {
            display.push(onlyOneMatch(display?.length, key))
        }
        return display
    }

    const onlyOneMatch = (colSpan, key) => (
        <p className={classNames("onlyOne", { [`col-span-${colSpan}`]: colSpan })} key={key}>
            <img src={mediaPath([ "/assets/icons/notifications.svg", "/assets/images/padel/icons/icons-notifications.svg" ])} alt="" />
            <span>Plus que 1 {tu("field")}</span>
        </p>
    )

    const displayForBookable = (bookable, summary, onlyOneDuration, index) => {
        const specificDuration = bookable?.duration % 60 === 0 ? "" : bookable?.duration % 60

        return (
            <React.Fragment key={index}>
                {summary && (
                    <div className="header">
                        <div className="hour">{dayjs(bookable.start).format("HH[h]mm")}</div>
                        <div className={`type ${bookable.resourceType === 1 || bookable.resourceType === 10 || bookable.resourceType === 2 || bookable.resourceType === 13 || bookable.resourceType === 15 ? "outdoor" : ""}`}>
                            {getResourceDisplay(bookable.resourceType, bookable.resourceTypeDisplay, mediaPath)}
                        </div>
                    </div>
                )}
                <div
                    title={summary ? null : "Réserver"}
                    className={classNames({
                        body: !summary,
                        onlyOneDuration: onlyOneDuration,
                        summary: summary,
                    })}
                    onClick={
                        async (e) => {
                            e.stopPropagation()
                            onSlotSelected(bookable)
                        }
                    }
                >
                    <div className="firstLine">
                        <div>{bookable.duration < 60 ? bookable.duration + " min" : Math.trunc(bookable.duration / 60) + "h" + specificDuration}</div>
                        {(
                            (bookable.resourceType === 10)
                                && (index === 0 || summary)
                        ) &&
                                <img src="/assets/icons/filmed.svg" alt="" />
                        }
                    </div>
                    <div className="middleLine">
                        <div className="priceNotif">
                            {discountFilter && bookable.discounts && bookable.discounts.find(discount => discount.label === discountFilter.label) ?
                                (<div className="discountPrice">
                                    <span className="fullPrice">{parseFloat(bookable.price) + "€"}</span>
                                    <span className="price">{" " + parseFloat((bookable.price - bookable.discounts.find(discount => discount.label === discountFilter.label).discount)?.toFixed(2)) + "€"}</span>
                                </div>)
                                :
                                <div className="price">{parseFloat(bookable.price) + "€"}</div>
                            }
                        </div>
                    </div>
                    <div className="pricePerPlayer">
                        {types?.length > 0 &&
                            CurrencyDisplay({
                                price: (
                                    discountFilter && bookable?.discounts?.find(discount => discount.label === discountFilter.label) ?
                                        bookable.price - bookable.discounts.find(discount => discount.label === discountFilter.label).discount
                                        :
                                        bookable.price
                                ) / types[0]?.categories[0]?.players,
                            })
                        } / joueur
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <CheckBlock
            key={id}
            cls={
                classNames("bookable no-cursor", {
                    disabled : bookable?.faked,
                })
            }
        >
            {bookable?.faked ? (
                <FoldableSection
                    key={"fold-faked-" + id}
                    summary={displayForBookable(bookable, true)}
                    detailsContent={
                        (<div className={"fakedSlotTaken"}>
                            <p>Créneau déjà pris</p>
                        </div>)
                    }
                    isBooking
                    disabled
                />
            ) : (
                <FoldableSection
                    key={"fold-" + id}
                    summary={displayForBookable(bookable.bookables?.[0], true)}
                    detailsContent={manyBookablesToDisplay(bookable, ("foldableSection-" + id))}
                    isSelected
                    isBooking
                    disabled
                />
            )}
        </CheckBlock>
    )
}

BookableItem.propTypes = {
    bookable: PropTypes.object,
    bookableListing: PropTypes.array,
    onSlotSelected: PropTypes.func.isRequired,
    types: PropTypes.array,
}

const OnlyOneMatch = ({ colSpan, tu, asset }) => (
    <p className={classNames("onlyOne", { [`col-span-${colSpan}`]: colSpan })}>
        <img alt="" src={asset} />
        <span>Plus que 1 {tu("field")}</span>
    </p>
)

OnlyOneMatch.propTypes = {
    asset: PropTypes.string,
    colSpan: PropTypes.number,
    tu: PropTypes.func,
}
