import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

import { SelectInput } from "../../../../../components/Inputs/Select/SelectInput.jsx"
import useMediaPath from "../../../../../hooks/useMediaPath"
import { getActivitiesForRessourcesTypesFavoriteSlot } from "../../functions"

import "./SlotStep.scss"

export const SlotStep2 = (props) => {
    const [ centerActivities, setCenterActivities ] = useState([])
    const mediaPath = useMediaPath()
    const { control, watch, setValue, register } = useFormContext()

    const selectedCenter = watch("center")
    const types = watch("type")

    useEffect(() => {
        if (selectedCenter)
        {changeCenterId(selectedCenter)}
    }, [ selectedCenter ])

    function changeCenterId(id) {
        const centerToSelect = props.bookables?.find(center => center[0] === parseInt(id))
        const activities = getActivitiesForRessourcesTypesFavoriteSlot(centerToSelect?.types)
        const centerActivities = activities.map(activity =>  ({ label: activity.name, value: activity.id }))
        setCenterActivities(centerActivities)

        if (centerActivities.length === 1) {
            setValue("type", centerActivities[0].value)
        }

        if (!!types?.length && !centerActivities.map(activity => activity.value).some(activity => (types || []).includes(activity))) {
            setValue("type", null)
        }
    }

    return (
        <div className={"slot-step step-2"} key={"step2"}>
            <div className={"slot-step-header"}>
                <img src={mediaPath([ "/assets/icons/favoriteSlot/step-field.svg", "/assets/icons/favoriteSlot/padel/step-field.svg" ])} alt={"Step 3"} />
                <h2>où ?</h2>
            </div>
            <div className={"step-form-content"}>
                <div>
                    <SelectInput
                        label={"Centre"}
                        id={"center"}
                        required
                        options={props.bookables?.map(bookable => ({ label: bookable[1], value: bookable[0] })) ?? []}
                        {...register("center", { required: true, validate: value => value !== "" })}
                    />
                </div>
                <div>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value } }) => {
                            return (
                                <SelectInput
                                    label={"Terrain"}
                                    id={"type"}
                                    required
                                    value={(Array.isArray(value) ? value[0] : value?.toString()) ?? (value === undefined || value === null ? "" : value?.toString())}
                                    onChange={onChange}
                                    options={centerActivities}
                                />
                            )
                        }}
                        name={"type"}
                        rules={{ required: true, validate: (value) => value !== "" }}
                    />
                </div>
            </div>
        </div>
    )
}

SlotStep2.propTypes = {
    bookables: PropTypes.array,
    user: PropTypes.object,
}
