import dayjs from "dayjs"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"

import { gameMvpVote } from "../../../pages/league/api/leagueGame.js"
import {
    getLeagueGameDetailed,
    getLeagueGameDetailedPlayers,
    getLeagueGameDetailedVideos,
} from "../../../pages/league/api/myLeague.js"
import { useLeagueStore } from "../../../pages/league/store/useLeagueStore.js"
import ModalHandler from "../../../providers/Modal/ModalHandler.jsx"
import { LeaguePath } from "../../../routes/ApplicationPaths/LeaguePaths.js"
import Preloader from "../../loaders/preloader/preloader.jsx"
import MessagePopin from "../../popin/MessagePopin/MessagePopin.jsx"
import TabSwitcher from "../../TabSwitcher/TabSwitcher.jsx"
import { LeagueHeader } from "../LeagueHeader/LeagueHeader.jsx"
import MatchRecapCard from "../MatchRecapCard/MatchRecapCard.jsx"
import { MVPModal } from "../MVPModal/MVPModal.jsx"
import { TeamHeading } from "../TeamHeading/TeamHeading.jsx"

import "./LeagueLayout.scss"

export const LeagueGameLayout = () => {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ origin, setOrigin ] = useState(null)
    const userLoaded = useSelector(state => state.userLoaded)
    const { matchId } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [ tabs, setTabs ] = useState([])
    const lastTabKey = useRef(0)
    const {
        selectedLeagueTeam,
        setCurrentGame,
        currentGame,
        setGameComposition,
        shouldRevalidate,
        setShouldRevalidate,
    } = useLeagueStore()

    useEffect(() => {
        if (location?.state?.from) {
            setOrigin(location.state.from)
        }
    }, [ location?.state?.from ])

    useEffect(() => {
        if ((selectedLeagueTeam?.id && matchId) || shouldRevalidate) {
            setIsLoading(true)
            setGameComposition([])
            getLeagueGameDetailed(matchId, selectedLeagueTeam.id).then(game => {
                if (game?.Message) {
                    setIsLoading(false)
                    navigate(LeaguePath.toLeagueTeamDetails(selectedLeagueTeam?.id))
                }
                if (location?.state?.openMvpVote && game?.id === parseInt(matchId)) {
                    navigate(location?.pathname, { replace: true })
                    openMvpModal(game)
                }
                setCurrentGame(game)
                setShouldRevalidate(false)
                setIsLoading(false)

                // add videos
                getLeagueGameDetailedVideos(selectedLeagueTeam.id, matchId).then(videos => {
                    setCurrentGame(game = { ...game, videos })
                }).catch()

                getLeagueGameDetailedPlayers(selectedLeagueTeam.id, matchId).then(players => {
                    setCurrentGame(game = { ...game, ...players })
                }).catch()
            }).catch(() => setIsLoading(false))
        }
    }, [ matchId, currentGame?.id, shouldRevalidate ])

    useEffect(() => {
        let baseTabs = []
        
        if (matchId && selectedLeagueTeam?.id && currentGame?.id) {
            baseTabs = [
                {
                    name: "Compo",
                    route: LeaguePath.toLeagueMatchCompo(selectedLeagueTeam?.id, matchId),
                },
                {
                    name: "Effectif",
                    route: LeaguePath.toLeagueMatchRoster(selectedLeagueTeam?.id, matchId),
                },
            ]

            if (dayjs(currentGame?.date).isBefore(dayjs())) {
                baseTabs = [
                    {
                        name: "Résumé",
                        route: LeaguePath.toLeagueMatchSummary(selectedLeagueTeam?.id, matchId),
                    },
                    ...baseTabs,
                ]

                baseTabs.push({
                    hidden: !currentGame?.videos?.length,
                    name: "Vidéos",
                    route: LeaguePath.toLeagueMatchVideos(selectedLeagueTeam?.id, matchId),
                })
            }
        }

        setTabs(baseTabs)
    }, [ matchId, selectedLeagueTeam?.id, currentGame?.id, currentGame?.videos?.length ])

    useEffect(() => {
        const currentTabKey = tabs.findIndex(tab => tab.route === location.pathname)
        if (currentTabKey !== -1) {
            lastTabKey.current = currentTabKey
        }
    }, [ location.pathname, tabs ])

    const openMvpModal = (game = currentGame) => {
        ModalHandler.show(MVPModal, {
            currentVote: game?.mvpInfos?.selectedPlayer,
            onValidate: voteForPlayer,
            players: (game?.mvpInfos?.players ?? []).filter(player => player?.id !== userLoaded?.contactId),
        })
    }

    const voteForPlayer = (selectedPlayer) => {
        setIsLoading(true)

        ModalHandler.hide(MVPModal)

        gameMvpVote(selectedLeagueTeam?.id, currentGame?.id, selectedPlayer?.championshipPlayerId).then((res) => {
            setCurrentGame({
                ...currentGame,
                mvpInfos: res,
            })
        }).catch((e) => {
            ModalHandler.show(MessagePopin, {
                message: <p>{e.response?.data?.error ?? "Le vote n'a pas été comptabilisé, veuillez réessayer."}</p>,
                title: "Une erreur est survenue",
            })
        }).finally(() => setIsLoading(false))
    }

    return isLoading || !tabs.length ? <Preloader fixed/> : (
        <>
            <LeagueHeader
                title="Détails du match"
                previous={origin ?? LeaguePath.toLeagueInfosCalendar(selectedLeagueTeam?.id)}
            >
                <TeamHeading team={selectedLeagueTeam}/>
            </LeagueHeader>
            <div className="c-row justify-center league-layout">
                <div className="c-col c-col--10 c-col--sm--12 no-padding">
                    <div>
                        <MatchRecapCard match={currentGame} team={selectedLeagueTeam} openMvpModal={openMvpModal}/>
                        <TabSwitcher tabs={tabs} defaultTab={tabs[0].route} origin={origin}/>
                        <AnimatePresence mode={"popLayout"}>
                            <motion.div
                                key={location.pathname}
                                variants={animationVariants(swipeDirection(tabs.findIndex(tab => tab.route === location.pathname), lastTabKey.current))}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                transition={{ duration: 0.6, ease: [ 0.16, 1, 0.3, 1 ] }}
                                className="tab-content"
                            >
                                <Outlet/>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </>
    )
}

const swipeDirection = (currentKey, lastKey) => currentKey > lastKey ? "30%" : "-30%"

const animationVariants = direction => ({
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, transition: { duration: 0 }, x: direction },
    initial: { opacity: 0, x: direction },
})

export default LeagueGameLayout
